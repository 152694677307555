<template>
  
  <div class="d-flex flex-column flex-grow-1 mt-1">
      <div class="my-auto" v-show="loadingIframe">
       
           <img src="/images/shield.png" class="vloading mb-3" alt=""
                        style="width:60px;">
       </div>
      <iframe id="blog" class="flex-grow-1 bg-white" @load="iframeLoaded"
      :src="pageSource" frameborder="0" v-show="!loadingIframe"></iframe>
  </div>

</template>

<script type='text/javascript'> (function(){ var s1=document.createElement('script'),s0=document.getElementsByTagName('script')[0]; s1.async=true; s1.src='https://us.floatbot.ai/portal/chatbot/?botId=604cc067429f9b56f83efa75'; s1.charset='UTF-8'; s1.setAttribute('crossorigin','*'); s0.parentNode.insertBefore(s1,s0); })(); </script>

<script>
export default {
data(){
    return{
        pageSource:'https://blog.protectmewell.com',
        loadingIframe:true,
    }
},
methods:{
    iframeLoaded(){
        this.loadingIframe = false;
    }
}
}
</script>

<style>
#blog{
    width:100%;
     
}
</style>